import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import {Link} from 'gatsby'
import BlockContent from './block-content'
import Container from './container'
import Img from 'gatsby-image';
import Footer from './footer';

function Project (props) {
  const {_rawBody, title, mainImage, publishedAt, relatedProjects} = props
  return (
    <article className="w-full">
        {props.mainImage && mainImage.asset && (
          <Img fluid={props.mainImage.asset.fluid} objectFit="cover" className="max-w-screen-xl mx-auto" alt={props.mainImage.alt}/>
        )}
      <div className="prose lg:prose-xl mx-auto">

        <Container>
          <div>
            <div>
              <h1>{title}</h1>
              <div class="text-gray-800 font-bold text-md">{format(props.startedAt, 'MMMM, YYYY')} - {format(props.endedAt, 'MMMM, YYYY')}</div>
              {_rawBody && <BlockContent blocks={_rawBody || []} />}
            </div>
            <aside>

              {relatedProjects && relatedProjects.length > 0 && (
                <div>
                  <h3>Related projects</h3>
                  <ul>
                    {relatedProjects.map(project => (
                      <li key={`related_${project._id}`}>
                        {project.slug ? (
                          <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                        ) : (
                          <span>{project.title}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </aside>
          </div>
        </Container>
      </div>
      <Footer />
    </article>
  )
}

export default Project
